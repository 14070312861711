<script setup lang="ts">
import EstateRibbons from '../shared/EstateRibbons.vue'
import EstateFavoriteTrigger from '../shared/EstateFavoriteTrigger.vue'
import EstateThumbGallery from './EstateThumbGallery.vue'
import EstateThumbContent from './EstateThumbContent.vue'
import EstateThumbContactPerson from './EstateThumbContactPerson.vue'
import type { EstateListing } from '~/types/api/EstateListing'

const props = defineProps<{
  estateData: EstateListing
  thumbType?: 'listing' | 'slider'
}>()

const estateLocaleLink = getEstateDetailLink({
  estateType: props.estateData.type,
  slug: props.estateData.slug,
  id: props.estateData.id,
})
</script>

<template>
  <article class="c-estate-thumb">
    <EstateRibbons :estate-data="estateData" class="c-estate-thumb__ribbons" />
    <EstateFavoriteTrigger
      :estate-data="estateData"
      class="c-estate-thumb__favorite"
    />

    <div class="c-estate-thumb__gallery">
      <EstateThumbGallery :estate-data="estateData" :thumb-type="thumbType" />
    </div>

    <div class="c-estate-thumb__contact-person">
      <EstateThumbContactPerson :contact-person="estateData.contact_person" />
    </div>

    <div class="c-estate-thumb__content">
      <EstateThumbContent
        :estate-locale-link="estateLocaleLink"
        :estate-data="estateData"
      />
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-estate-thumb {
  --content-border-color: var(--color-border);

  width: 100%;
  height: 100%;
  display: grid;
  grid-template:
    'gallery' auto
    'contact-person' 0px
    'content' 1fr
    / 1fr;
  transition: all var(--transition-duration) var(--transition-timing-function);
  position: relative;
  background-color: var(--color-light);

  @include breakpoint-up('lg') {
    &:hover {
      --content-border-color: transparent;

      border-radius: 6px;
      border-color: transparent;
      box-shadow: 0 4px 8px 0
        hsla(
          var(--color-dark-h),
          var(--color-dark-s),
          var(--color-dark-l),
          0.08
        );

      :deep(.c-estate-thumb-gallery__arrows) {
        --estate-thumb-gallery--arrows--opacity: 1;
      }

      :deep(.c-estate-favorite-trigger) {
        --estate-favorite-trigger--background-opacity: 0.75;
      }
    }
  }

  &__ribbons {
    grid-area: gallery;
    position: absolute;
    top: 10px;
    left: 14px;
    z-index: 2;
  }

  &__favorite {
    grid-area: gallery;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 2;
  }

  &__gallery {
    grid-area: gallery;
    width: 100%;
    aspect-ratio: 5 / 3;
    flex: 0 0 auto;
    min-height: 0;
    overflow: hidden;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  &__contact-person {
    grid-area: contact-person;
    position: relative;
    z-index: 2;
  }

  &__content {
    min-width: 0;
    display: flex;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    grid-area: content;
    padding: 26px 32px;
    transition: border-color var(--transition-duration)
      var(--transition-timing-function);
    border: 1px solid var(--content-border-color);

    @include breakpoint-down('md') {
      padding: 20px 24px;
    }
  }
}
</style>
